<template>
  <main>
    <table class="table table-striped table-sm table-bordered font-size-12">
      <tr>
        <th>Factores</th>
        <th>Respuesta</th>
      </tr>
      <tr v-for="item in riesgos" :key="item.id">
        <td>{{ item.nombre }}</td>
        <td>
          <select v-model="item.respuesta" class="form-control">
            <option value="SI">SI</option>
            <option value="NO">NO</option>
          </select>
        </td>
      </tr>
    </table>
    <span class="text-danger d-block" v-if="$v.riesgos.$error && $v.riesgos.$dirty">Debe completar el formulario</span>
    
  </main>
</template>
<script>
import riesgoCardiovascularService from "../../../../services/riesgoCardiovascularService";
import historiaRiesgosCardiovascularService from "../../../../services/historiaRiesgosCardiovascularService";
import {isEmpty} from "lodash";

import {required} from "vuelidate/lib/validators";
export default {
  props: ["idHistoria", "finalizada", "idUsuario"],
  data() {
    return {
      respuesta: {},
      riesgos: {},
      tipo:'no_modificable',
      tieneRespuestas : false
    };
  },
  validations () {
    return {
      riesgos : {
        $each : {
          respuesta : {required}
        }
      }
    }
  },
  methods: {
    async cargarRiesgos() {
      
      const response = await riesgoCardiovascularService.showByTipo(this.tipo);
      const respuestas = await historiaRiesgosCardiovascularService.showByHc(this.idHistoria);

      if(!isEmpty(respuestas.data)) this.tieneRespuestas = true;
      
      this.riesgos = response.data.map((riesgo) => {
            
            let respuesta = '';

            if(!isEmpty(respuestas.data)){
                
                const tieneRespuesta = respuestas.data.find(x => parseInt(x.id_riesgo) === parseInt(riesgo.id));
                
                if(tieneRespuesta !== undefined){
                    respuesta = tieneRespuesta.respuesta;
                }

            }

            return {
                ...riesgo,
                respuesta: respuesta,
            };
      });
    },
    retornar(){

      this.$v.$touch();

      if(this.$v.$invalid) return;

      let riesgo_no_modificable = {
        id_historia: this.idHistoria,
        respuestas: this.riesgos,
      };

      return riesgo_no_modificable;
    },
  },
  created() {
    this.cargarRiesgos();
  },
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>