<template>
    <main>
       <div class="mt-2">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Riesgos No Modificables</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Riesgos Modificables</a>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <riesgosNoModificable ref="riesgosNoModificable" :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario"></riesgosNoModificable>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                      <riesgosModificable ref="riesgosModificable" :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario"></riesgosModificable>
                </div>
                
            </div>
       </div>
    </main>
</template>
<script>
import historiaRiesgosCardiovascularService from '../../../../services/historiaRiesgosCardiovascularService';
import riesgosModificable from "./riesgosModificable";
import riesgosNoModificable from "./riesgosNoModificable";
import Toast from '../../../../components/common/utilities/toast';
import {isEmpty} from 'lodash';
import {required} from "vuelidate/lib/validators";

export default {
    props: ["idHistoria", "finalizada", "idUsuario"],
    components: {riesgosModificable,riesgosNoModificable},
    data() {
        return {
            tieneRespuestas : false,
            modificable:'',
            no_modificable:''
        }
    },
     validations(){
        return {
            modificable:{required},
            no_modificable:{required},
        }
    },
    methods: {
        validate(){
            this.$refs.riesgosModificable.$v.$touch();
            this.$refs.riesgosNoModificable.$v.$touch();
            
            if (!this.$refs.riesgosModificable.$v.$invalid) this.modificable = 'S';
            if (!this.$refs.riesgosNoModificable.$v.$invalid) this.no_modificable = 'S';
            
        },
        async retornar(){


            this.$v.$touch();

            if(this.$v.$invalid) return;
            
            const riesgo_modificable = this.$refs.riesgosModificable.retornar();
            const riesgo_no_modificable = this.$refs.riesgosNoModificable.retornar();

            try {

                let historia_riesgos={
                    id_historia:this.idHistoria,
                    riesgos : [
                        ...riesgo_modificable.respuestas,
                        ...riesgo_no_modificable.respuestas
                    ]
                };

                this.LoaderSpinnerShow();
                
                if(this.tieneRespuestas){
                    await historiaRiesgosCardiovascularService.update(historia_riesgos);
                     this.tieneRespuestas = true;
                }else{
                    await historiaRiesgosCardiovascularService.store(historia_riesgos);
                    this.tieneRespuestas = true;
                }

                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        }
    },
    async created() {
      const respuestas = await historiaRiesgosCardiovascularService.showByHc(this.idHistoria);
      if(!isEmpty(respuestas.data)){
          this.tieneRespuestas = true;
      }  
    },
}
</script>