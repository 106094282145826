<template>

  <main ref="resumen" id="resumen">
    <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
    <div class="py-2"><examenFisicoShow :idHistoria="idHistoria"></examenFisicoShow></div>
    <div class="py-2"><exploracionFisicaShow :idHistoria="idHistoria"></exploracionFisicaShow></div>
    <div class="py-2"><revision-sistemas-show :id-historia="idHistoria"></revision-sistemas-show></div>
    <div class="py-2"><ordenClinicaShow :idHistoria="idHistoria"></ordenClinicaShow></div>
    <div class="py-2"><formulaMedicaShow :idHistoria="idHistoria"></formulaMedicaShow></div>
    <div class="py-2"><impresionDxShow :idHistoria="idHistoria"></impresionDxShow></div>
    <div class="py-2"><valeShow :idHistoria="idHistoria" :usuario="usuario"></valeShow></div>
    <div class="py-2"><reporteExamenesShow :idHistoria="idHistoria"></reporteExamenesShow></div>
    <div class="py-2"><vacunasShow :idHistoria="idHistoria" :idUsuario="idUsuario"></vacunasShow></div>
    <div class="py-2"><situacionesPersonalesShow :idHistoria="idHistoria" :idUsuario="idUsuario"></situacionesPersonalesShow></div>
    <div class="card-header bg-light "><span><i class="fa fa-syringe"> </i> Riesgo Cardiovascular</span> </div>
    <div class="card-body">
      <div class="py-2"><riesgosNoModificableShow :idHistoria="idHistoria"></riesgosNoModificableShow></div>
      <div class="py-2"><riesgosModificableShow :idHistoria="idHistoria"></riesgosModificableShow></div>
    </div>
    <div class="py-2"><finnisRiskShow :idHistoria="idHistoria" ></finnisRiskShow></div>
    <div class="py-2"><tannerComponentShow :idHistoria="idHistoria" :idUsuario="idUsuario"></tannerComponentShow></div>
    <div class="py-2"><intervenciones-show :idHistoria="idHistoria"></intervenciones-show></div>
    <div class="py-2"><recomendaciones-show :idHistoria="idHistoria"></recomendaciones-show></div>
  </main>

</template>
<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../../base/impresionDX/impresionDxShow";
import valeShow from "../../base/baseVale/valeShow";
import exploracionFisicaShow from "../../base/examenFisico/exploracionFisicaShow";
import reporteExamenesShow from "../../base/baseReporteExamenesAdolescente/reporteExamenesShow";
import vacunasShow from "../../base/baseVacunas/index/vacunasShow";
import situacionesPersonalesShow from "../../base/baseSituacionesPersonales/situacionesPersonalesShow";
import tannerComponentShow from "../../base/baseTanner/tannerComponentShow";
import intervencionesShow from "@/views/historias/base/intervenciones/intervencionesShow";
import RecomendacionesShow from "@/views/historias/base/recomendaciones/recomendacionesShow";
import RevisionSistemasShow from "@/views/historias/base/basePartesRevisionSistemas/revisionSistemasShow";
import riesgosModificableShow from "../../base/baseRiesgoCardiovascular/riesgosModificableShow";
import riesgosNoModificableShow from "../../base/baseRiesgoCardiovascular/riesgosNoModificableShow";
import finnisRiskShow from "../../base/baseFinnisRisk/finnisRiskShow";

export default {
  props: ['idHistoria','idUsuario', 'usuario'],
  components:{
    RevisionSistemasShow,
    RecomendacionesShow,
    intervencionesShow, motivoConsultaShow,
    examenFisicoShow,
    ordenClinicaShow,
    formulaMedicaShow,
    impresionDxShow,
    valeShow,
    exploracionFisicaShow,
    reporteExamenesShow,
    vacunasShow,
    situacionesPersonalesShow,
    tannerComponentShow,riesgosNoModificableShow,finnisRiskShow,riesgosModificableShow
  },
  data () {
    return {
      imgData : '',
    }
  }
}
</script>
<style scoped>
.resumen-container {
  height:300px;
  overflow-y:scroll
}

</style>