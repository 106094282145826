<template>
    <main>
        <table class=" table table-striped table-sm table-bordered font-size-12">
            <tr>
                <th>Rango</th>
                <th>Condiciones Estructurales</th>
                <th>Presencia</th>
                <th>Integridad</th>
            </tr>
            <tr v-for="item in condiciones" :key="item.id">
                <td>TODAS LAS EDADES</td>
                <td>{{item.condiciones_estructurales}}</td>
                <td>
                    <select v-model="item.presencia" class="form-control">
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                    </select>
                </td>
                <td>
                    <select v-model="item.integridad" class="form-control">
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                    </select>
                </td>
            </tr>
        </table>
        <div class="col-12 d-flex justify-content-end">
        <button @click="guardar()" class="btn btn-success btn-sm">Guardar</button>
        </div>
    </main>
</template>
<script>
import condicionesEstructuralesValeService from "../../../../services/condicionesEstructuralesValeService";
import historiaCondicionesEstructuralesValeService from "../../../../services/historiaCondicionesEstructuralesValeService";
import {isEmpty} from "lodash";
import Toast from '../../../../components/common/utilities/toast';
export default {
    props: ["idHistoria", "finalizada", "idUsuario"],
    data(){
        return {
            presencia: {},
            integridad: {},
            condiciones:{},
            tienePresencias:false,
            tieneIntegridades:false,
        }
    },
    methods:{
        async cargarCondiciones(){
            const response = await condicionesEstructuralesValeService.index();
                      
            const respuestas = await historiaCondicionesEstructuralesValeService.showByHc(this.idHistoria);

            if(!isEmpty(respuestas.data)) {
                this.tienePresencias = true;
                this.tieneIntegridades = true;
            }
            
            
            this.condiciones = response.data.map((condicion) => {
                    
                let presencia = '';
                let integridad='';

                if(!isEmpty(respuestas.data)){
                    
                    const tienePresencia = respuestas.data.find(x => parseInt(x.id_condicion) === parseInt(condicion.id));
                    
                    if(tienePresencia !== undefined){
                        presencia = tienePresencia.presencia;
                    }

                    const tieneIntegridad = respuestas.data.find(x => parseInt(x.id_condicion) === parseInt(condicion.id));
                    if(tieneIntegridad !== undefined){
                        integridad = tieneIntegridad.integridad;
                    }
                }

                return {
                    ...condicion,
                    presencia: presencia,
                    integridad: integridad,
                };
            });
        },
        async guardar() {
            try {
                let historia_condiciones = {
                id_historia: this.idHistoria,
                respuestas: this.condiciones,
                };

                this.LoaderSpinnerShow();
                
                if(this.tienePresencias && this.tieneIntegridades){
                    await historiaCondicionesEstructuralesValeService.update(historia_condiciones);
                }else{
                    await historiaCondicionesEstructuralesValeService.store(historia_condiciones);
                    this.tienePresencias = true;
                    this.tieneIntegridades = true;
                }

                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
    },
    created(){
        this.cargarCondiciones();
    }
}
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>