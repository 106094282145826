<template>
  <main>
    <div class="mt-2">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Riesgos Generales</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Condiciones Estructurales</a>
        </li>
        <li class="nav-item" role="presentation" v-if="edad < 13">
          <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Items Valoración</a>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <riesgosGenerales :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario" :usuario="usuario"></riesgosGenerales>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <condicionesEstructurales :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario"></condicionesEstructurales>
        </div>
        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" v-if="edad < 13">
          <itemsValoracion :usuario="usuario" :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario"></itemsValoracion>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import itemsValoracion from "./itemsValoracion";
import riesgosGenerales from "./riesgosGenerales";
import condicionesEstructurales from "./condicionesEstructurales";
import dayjs from "dayjs";

export default {
  props: ["idHistoria", "finalizada", "idUsuario", 'usuario'],
  components: {itemsValoracion,riesgosGenerales,condicionesEstructurales},
  computed : {
    edad: function() {
      const now = dayjs();
      return now.diff(this.usuario.fecha_nacimiento, "years");
    },
  }
}
</script>