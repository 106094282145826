<template>
  <main>
    <table class="table table-striped table-sm table-bordered font-size-12">
      <tr ><th colspan="2">Factores de riesgo no modificables</th></tr>
      <tr>
        <th>Factores</th>
        <th>Respuesta</th>
      </tr>
      <tr v-for="item in riesgos" :key="item.id">
        <td>{{ item.nombre }}</td>
        <td>
          {{item.respuesta}}
        </td>
      </tr>
    </table>
  </main>
</template>
<script>
import riesgoCardiovascularService from "../../../../services/riesgoCardiovascularService";
import historiaRiesgosCardiovascularService from "../../../../services/historiaRiesgosCardiovascularService";
import {isEmpty} from "lodash";
export default {
  props: ["idHistoria"],
  data() {
    return {
      respuesta: {},
      riesgos: {},
      tipo:'no_modificable',
    };
  },
  methods: {
    async cargarRiesgos() {
      
      const response = await riesgoCardiovascularService.showByTipo(this.tipo);
      const respuestas = await historiaRiesgosCardiovascularService.showByHc(this.idHistoria);

      if(!isEmpty(respuestas.data)) this.tieneRespuestas = true;
      
      this.riesgos = response.data.map((riesgo) => {
            
            let respuesta = '';

            if(!isEmpty(respuestas.data)){
                
                const tieneRespuesta = respuestas.data.find(x => parseInt(x.id_riesgo) === parseInt(riesgo.id));
                
                if(tieneRespuesta !== undefined){
                    respuesta = tieneRespuesta.respuesta;
                }

            }

            return {
                ...riesgo,
                respuesta: respuesta,
            };
      });
    },
  },
  created() {
    this.cargarRiesgos();
  },
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>