<template>
    <main>
       <form>
            <div class="form-row" v-for="item in tanner_estadio" :key="item.id">
               <h6>{{item.nombre}}</h6>
               <table class="table table-bordered table-sm font-size-12">
                   <tr>
                       <th></th>
                       <th>Tanner</th>
                       <th>Descripcion</th>
                    </tr>
                   <tr v-for="estadio in item.estadios" :key="estadio.id">
                       <td class="text-center align-middle"> 
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" :value="estadio.id" :name="item.nombre" v-model="item.estadio"/>
                            </div>
                       </td>
                       <td class="text-center align-middle">{{estadio.estadio}}</td>
                       <td class="align-middle">{{estadio.descripcion}}</td>
                   </tr>
               </table>
            </div>
            <span class="text-danger d-block" v-if="$v.tanner_estadio.$error && $v.tanner_estadio.$dirty">Debe completar el formulario</span>
        </form>
    </main>
</template>
<script>
import tipoTannerService from "../../../../services/tipoTannerService";
import tannerValoracionService from "../../../../services/tannerValoracionService";
import Toast from '../../../../components/common/utilities/toast';
import {isEmpty} from "lodash";
import {required} from 'vuelidate/src/validators';

export default {
    props: ['idHistoria','finalizada', 'sexo'],
    data(){
        return {
            tanner_estadio:[],
        }
    },
    validations () {
        return {
            tanner_estadio : {
                $each : {
                    estadio : {required}
                }
            }
        }
    },
    methods: {
        async cargarTanners(){
            const response = await tipoTannerService.index({sexo : this.sexo});
            this.tanner_estadio = response.data.map(tanner => {
                return {
                    ...tanner,
                    estadio : '',
                }
            });
        },
        async save(){
            try {

                this.$v.$touch();
                if(this.$v.$invalid) return;

                const valoracion_tanner = this.tanner_estadio.map(tipo => {
                    return {
                        id_estadio : tipo.estadio,
                        id_historia : this.idHistoria
                    }
                });

                this.LoaderSpinnerShow(); 

                await tannerValoracionService.store(valoracion_tanner);

                this.LoaderSpinnerHide();

               Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        }
    },
    
   async created (){
        await this.cargarTanners();
        const response = await tannerValoracionService.showByHc(this.idHistoria);
        if(!isEmpty(response.data)){
            response.data.forEach(x => {
                let index = this.tanner_estadio.findIndex(tanner => tanner.id === x.estadio.tipo.id);
                this.tanner_estadio[index].estadio = x.id_estadio;
            });
        }
    }
}
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>